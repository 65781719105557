
.dropdown-item{
  font-size: 14px !important;
}
.table-col{
    width: 12.4285714286%;
}
.table-col-participant{
    width: 14%;
    font-weight: 1%;
}

.shift-unapproved {
    background-color: rgb(212, 219, 238) !important;
}

.shift-approved {
    background-color: rgb(135, 202, 161) !important;
}

.shift-confirmed {
    background-color: rgb(210, 221, 163) !important;
}

.text{
    color: rgb(33, 65, 65);
}
 
.shifts:hover  .actions{
    display: block;
}

div .actions {
    display: none;
}

.actions div {
    width: 33%;
    height: 30%;
    font-weight: bolder;
    font-size: 100%;
    background-color: rgb(96, 86, 102);
}
.text{
    font-size: 13px;
}
.cell{
    height: 70px;
}

/* TABLE CSS */

  .table--roster, .td-roster {
    border-collapse: collapse;
  }
  
  .thead-roster {
    display: table; /* to take the same width as tr */
    width: calc(100% - 17px); /* - 17px because of the scrollbar width */
  }
  
  .tbody-roster {
    display: block; /* to enable vertical scrolling */
    max-height: 600px; /* e.g. */
    overflow-y: scroll; /* keeps the scrollbar even if it doesn't need it; display purpose */
  }
  
  .th-roster, .td-roster {
    word-break: break-all; /* 4. */
  }
  
  .tr-roster {
    display: table; /* display purpose; th's border */
    box-sizing: border-box; /* because of the border (Chrome needs this line, but not FF) */
  }
  
  .td-roster {
    text-align: center;
    border-bottom: none;
    border-left: none;
  }

  .cancell-icon{
    color: red;
  }

  tr{
    width: 100% !important;
  }
  /* Overiding boostrape modal css */
  .modal-dialog{
    margin-top: 8% !important;
  }

  .modal-backdrop.show {
    opacity: .2 !important;
}
  