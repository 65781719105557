.nav-item a {
    width: 100%;
    display: block;
}

.nav-item {
    margin-left: 30px;
}

.tag{
    background-color: rgb(241, 103, 10);
}

nav {
    width: 100%;
}