.reports-page{
    width: 100%
}

@media only screen and (max-width: 1200px) {
    .reports-page{
        width: 100%
    }
}

@media only screen and (min-width: 1200px) {
    .reports-page{
        width: 100%
    }
}