
.profile {
  padding-top: 0.2%;
  text-align: center;
  height: 30px;
  width: 30px;
  background-color: blanchedalmond;
  border-radius: 50%;
}
.body{
  background-color: rgb(36, 33, 29);
}