.input-labels{
    font-weight: bolder;
}

.text-alert{
    font-size: 14px;
    display: none;
}
.modal-body{
    padding-top: 0 !important;
}
.rbt-input-main {
    border: 1px solid blue !important;
}

.modal-body{
    min-height:400px,
}

.participant-shift-row td{
    height: 5px !important;
    font-size: 13px;
}
.participant-shift-row{
    max-height: 3px;
}

.table-shifts{
    min-height: 300px;
}
.day-shift-approved {
    color:green;
    font-size:xx-small;
}

.day-shift-confirmed {
    /* color: rgb(185, 182, 5) !important; */
    color:rgb(184, 158, 12);
    font-size: xx-small;
}

@media (min-width: 992px){
    .participantShiftsModal > .modal-lg, .participantShiftsModal > .modal-xl {
        max-width: 1000px !important;
    }
}
